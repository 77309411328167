import React, { useEffect, useState } from "react";
import * as styles from "./TextAreaV2.module.scss";
import cn from "classnames";
import { useInputError, ValidationProps } from "@components/Input/Input";
import { Colors } from "@helpers/Colors";
import { TypographyV2 } from "..";

type InputProps = ValidationProps & {
	/** Specific class name to give to the Input  */
	inputClassName?: string;
	/** Input label also be placeholder of the Input */
	label?: string;
	/** Input value */
	value: string;
	/** theme of the input (default=false)*/
	lightmode?: boolean;
	/** Called when the value of the input changes */
	onChange: (e: string) => void;
	/** class name to go on the wrapper */
	className?: string;
	/** Apply width 100% to the input */
	fullWidth?: boolean;
	/** Email, name, etc */
	autoComplete?: string;
	/** Placeholder value, will conflict with label if label exists */
	placeholder?: string;
	/** Optional error color */
	inputErrorColor?: Colors;
	// disabled
	disabled?: boolean;
	//   styling label
	labelClassName?: string;
};

export default function TextAreaV2({
	label,
	value,
	onChange,
	lightmode = false,
	inputClassName,
	className,
	fullWidth = false,
	autoComplete,
	placeholder,
	inputErrorColor,
	customErrorMessage,
	labelClassName,
	disabled,
	...validationProps
}: InputProps) {
	const { error, onBlur } = useInputError(value, validationProps);
	return (
		<div
			className={cn(className, styles.genemodInputWrapper, {
				[styles.genemodInputWrapper__lightmode]: lightmode,
			})}
		>
			<div className={styles.inputNow}>
				{label && (
					<div>
						<TypographyV2
							variant="BODY_TEXT_MEDIUM"
							// weight=""
							className={cn(styles.inputlabelNow, labelClassName)}
							color="text-secondary"
						>
							{label}
						</TypographyV2>
					</div>
				)}
				<textarea
					style={{ width: fullWidth ? "100%" : undefined }}
					className={cn(styles.genemodInput, inputClassName, {
						[styles.genemodInput__error]: !!error,
					})}
					value={value}
					onChange={(e) => onChange(e.target.value)}
					placeholder={placeholder || " "}
					onBlur={onBlur}
					autoComplete={autoComplete}
					disabled={disabled}
				/>
			</div>
			{!!error && (
				<TypographyV2
					variant="BODY_TEXT_MEDIUM"
					weight="REGULAR"
					className={styles.inputError}
					color={inputErrorColor}
				>
					{customErrorMessage ? customErrorMessage : error}
				</TypographyV2>
			)}
		</div>
	);
}
