// extracted by mini-css-extract-plugin
export var body = "index-module--body--D18vm";
export var connectText = "index-module--connectText--8oC+U";
export var container = "index-module--container--9GZeS";
export var containerGrid = "index-module--containerGrid--0QQqU";
export var fullWidthImage = "index-module--fullWidthImage--Ee-0W";
export var horizontalRule = "index-module--horizontalRule--+k8j1";
export var input = "index-module--input--7WUR-";
export var inputGrid = "index-module--inputGrid---WaOa";
export var institutionCard = "index-module--institutionCard--pikwl";
export var integrated_bg = "index-module--integrated_bg--W-Ir1";
export var joinInstitutions = "index-module--joinInstitutions--V37pn";
export var label = "index-module--label--qCd-a";
export var limitWidthOnLargeScreens = "index-module--limitWidthOnLargeScreens--yqP0-";
export var logoImage = "index-module--logoImage--f30+P";
export var logos = "index-module--logos--c1fsx";
export var margins = "index-module--margins--jD0de";
export var message = "index-module--message--K9rk5";
export var mobileCentre = "index-module--mobileCentre--IWbyH";
export var privacyLink = "index-module--privacyLink--4EtBI";
export var rightContainer = "index-module--rightContainer--p5u5D";
export var submitButton = "index-module--submitButton--TCgqg";
export var submitButtonContainer = "index-module--submitButtonContainer--fob-5";
export var submitSection = "index-module--submitSection--3CuVd";
export var submit_success = "index-module--submit_success--+HdbP";
export var submit_success_text = "index-module--submit_success_text--x-HDX";
export var textArea = "index-module--textArea--zgWmG";
export var textContainer = "index-module--textContainer--Yhgm7";