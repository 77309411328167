import React from "react";
import * as styles from "./index.module.scss";
import TextAreaV2 from "@components/TextAreaV2/TextAreaV2";
import { useHubspotForm } from "@helpers/Hooks";
import { StaticImage } from "gatsby-plugin-image";
import cn from "classnames";
import { PageLayout, TypographyV2 } from "@components";
import Input from "@components/InputV2/InputV2";
import Select from "@components/SelectV2/SelectV2";
import Margins from "@components/Margins/Margins";
import { SubmitbuttonV2 } from "@components/Button_new/Button";
import GenemodLink from "@components/Link/Link";
import freeEmailDomains from "free-email-domains";
import { JoinInstitutionsV2 } from "pages/landingpagev2/_components/_JoinInstitutionsV2/_JoinInstitutionsV2";

const CONTACT_US_SUBMISSION_ROUTE = `https://api.hsforms.com/submissions/v3/integration/submit/19551518/3326fd9a-51f1-4da7-9e30-bad4d8c34063`;

export const SUBJECT_LIST = Object.freeze([
	"Account related",
	"Billing",
	"Feature request",
	"Troubleshoot",
	"Other",
] as const);

export default function ContactUsPage({ location }: any) {
	const { Option } = Select;
	const { inputProps, handleSubmit, submitStatus } = useHubspotForm(
		{
			email: location?.state?.email || "",
			firstname: "",
			lastname: "",
			subject_2022_april_contact_us: "",
			message: "",
		},
		CONTACT_US_SUBMISSION_ROUTE
	);

	const validateFreeEmail = (value: string): boolean => {
		const emailDomain = value.split("@");
		if (emailDomain[1] && freeEmailDomains.includes(emailDomain[1])) {
			return false;
		}
		return true;
	};

	return (
		<PageLayout seoOptions={{ type: "PREDEFINED", pageName: "contact-us" }}>
			{/* <Margins className={styles.main_margin}> */}
			<div className={styles.container}>
				<Margins className={styles.margins}>
					{/* new layout */}
					<div className={styles.containerGrid}>
						{/* left */}
						<div className={styles.leftContainer}>
							<div className={styles.textContainer}>
								<TypographyV2
									variant="HEADING_1"
									weight="MEDIUM"
									color="brand-500"
									className={styles.mobileCentre}
								>
									Contact us
								</TypographyV2>

								<hr className={styles.horizontalRule} />
								<TypographyV2
									variant="HEADING_5"
									weight="MEDIUM"
									color="text-helper"
									className={styles.mobileCentre}
								>
									You're in great company
								</TypographyV2>
								<TypographyV2
									variant="BODY_TEXT_EXTRA_LARGE"
									weight="REGULAR"
									color="text-helper"
									className={cn(
										styles.connectText,
										styles.mobileCentre
									)}
								>
									Join the hundreds of trusted institutions
									that choose Genemod to increase productivity{" "}
								</TypographyV2>
							</div>
							<div className={styles.logos}>
								<StaticImage
									src="../../assets/demo/image 17.png"
									alt="Nautilus"
									className={styles.logoImage}
								/>
								<StaticImage
									src="../../assets/demo/pfizer.png"
									alt="Pfizer"
									className={styles.logoImage}
								/>
								<StaticImage
									src="../../assets/demo/bayer.png"
									alt="Bayer"
									className={styles.logoImage}
								/>
								<StaticImage
									src="../../assets/demo/eurofins.png"
									alt="Eurofins"
									className={styles.logoImage}
								/>
								<StaticImage
									src="../../assets/demo/harvard.png"
									alt="Harvard"
									className={styles.logoImage}
								/>
								<StaticImage
									src="../../assets/demo/stanford.png"
									alt="Stanford"
									className={styles.logoImage}
								/>
							</div>
						</div>
						{/* Right container */}
						<div className={styles.rightContainer}>
							<div className={styles.body}>
								<Input
									labelClassName={styles.label}
									className={styles.input}
									inputClassName={styles.input}
									label={"Work email"}
									{...inputProps("email")}
									autoComplete="email"
									fullWidth
									emailValidator
									inputErrorColor="error-400"
									disabled={
										submitStatus === "SUCCESS" ||
										submitStatus === "SUBMITTING"
									}
									validators={[
										{
											validator: (val) =>
												validateFreeEmail(val),
											error: "Please enter a business email",
										},
									]}
								/>

								<div className={styles.inputGrid}>
									<Input
										label={"First name"}
										labelClassName={styles.label}
										className={styles.input}
										inputClassName={styles.input}
										{...inputProps("firstname")}
										autoComplete="given-name"
										fullWidth
										required
										inputErrorColor="error-400"
										customErrorMessage="Required"
										disabled={
											submitStatus === "SUCCESS" ||
											submitStatus === "SUBMITTING"
										}
									/>
									<Input
										label={"Last name"}
										labelClassName={styles.label}
										className={styles.input}
										inputClassName={styles.input}
										{...inputProps("lastname")}
										autoComplete="family-name"
										fullWidth
										required
										inputErrorColor="error-400"
										customErrorMessage="Required"
										disabled={
											submitStatus === "SUCCESS" ||
											submitStatus === "SUBMITTING"
										}
									/>
								</div>
								<Select
									label={"Subject"}
									{...inputProps(
										"subject_2022_april_contact_us"
									)}
									fullWidth
									required
									inputErrorColor="error-400"
									customErrorMessage="Required"
									disabled={
										submitStatus === "SUCCESS" ||
										submitStatus === "SUBMITTING"
									}
								>
									{SUBJECT_LIST.map((sub, index) => (
										<Option key={sub} value={sub}>
											{sub}
										</Option>
									))}
								</Select>
								<TextAreaV2
									label={"Message"}
									{...inputProps("message")}
									lightmode
									labelClassName={styles.label}
									className={styles.textArea}
									required
									inputErrorColor="error-400"
									customErrorMessage="Required"
									inputClassName={styles.textArea}
									disabled={
										submitStatus === "SUCCESS" ||
										submitStatus === "SUBMITTING"
									}
								/>
								<div className={styles.submitSection}>
									<SubmitbuttonV2
										submitStatus={submitStatus}
										onClick={handleSubmit}
										className={styles.submitButton}
										containerClassName={
											styles.submitButtonContainer
										}
									>
										Submit
									</SubmitbuttonV2>
								</div>
							</div>
						</div>
					</div>
				</Margins>
			</div>
			<div className={styles.integrated_bg}>
				<JoinInstitutionsV2
					outerContainerClassName={styles.joinInstitutions}
				/>
			</div>
			{/* </Margins> */}
		</PageLayout>
	);
}
