// extracted by mini-css-extract-plugin
export var genemodInput = "TextAreaV2-module--genemodInput--E1PZW";
export var genemodInputContainer = "TextAreaV2-module--genemodInputContainer--2Dn-u";
export var genemodInputWrapper = "TextAreaV2-module--genemodInputWrapper--S7RMt";
export var genemodInputWrapper__lightmode = "TextAreaV2-module--genemodInputWrapper__lightmode--WqVfQ";
export var genemodInput__error = "TextAreaV2-module--genemodInput__error--Vi8vA";
export var inputError = "TextAreaV2-module--inputError--0W2mD";
export var inputLabel = "TextAreaV2-module--inputLabel--U57F7";
export var inputNow = "TextAreaV2-module--inputNow--UdqJ4";
export var inputlabelNow = "TextAreaV2-module--inputlabelNow--kDTcm";
export var limitWidthOnLargeScreens = "TextAreaV2-module--limitWidthOnLargeScreens--N1Fp9";